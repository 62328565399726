import { Injectable } from '@angular/core';
import { City } from './city';
import { Event } from './event';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private cities: City[];
  private events: Event[];
  private selectedCity: string;
  private startDate: string;
  private endDate: string;

  constructor() { }

  setCities(cities: City[]) {
    this.cities = cities;
  }

  getCities(): City[] {
    return this.cities;
  }

  setEvents(events: Event[]) {
    this.events = events;
  } 

  getEvents(): Event[] {
    return this.events;
  }

  setSelectedCity(city: string) {
    this.selectedCity = city;
  }

  getSelectedCity(): string {
    return this.selectedCity;
  }

  setStartDate(startDate: string) {
    this.startDate = startDate;
  }

  getStartDate(): string{
    return this.startDate
  }

  setEndDate(endDate: string) {
    this.endDate = endDate;
  }

  getEndDate(): string {
    return this.endDate;
  }
}
