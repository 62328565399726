import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { Event } from '../event';
import { EventService } from '../event.service';
import { UiService } from '../ui.service';
import { DateService } from '../date.service';
import { DataService } from '../data.service';
import { EventInProximity } from '../eventInProximity';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {

  event: Event;
  eventLoaded: boolean;
  eventsInProximity: EventInProximity[];
  eventsInProximityLoaded: boolean;
  partitionKey: string;
  rowKey: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private uiService: UiService,
    private dateService: DateService,
    private dataService: DataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.partitionKey = this.activatedRoute.snapshot.paramMap['params']['partition'];
    this.rowKey = this.activatedRoute.snapshot.paramMap['params']['id'];
    this.getEvent(this.partitionKey, this.rowKey);
  }

  getEvent(partitionKey: string, rowKey: string): void {
    this.eventService.getEvent(partitionKey, rowKey)
      .subscribe(  
        event =>  {
          this.event = event;
          this.eventLoaded = true;
          this.eventService.getEventsInProximity(this.event.venue)
            .subscribe(
              eventsInProximity => {
                this.eventsInProximity = eventsInProximity;
                this.eventsInProximityLoaded = true;
              }
            );
        }
      );
  }

  getDomainIcon(domainName: string): string {
    return this.uiService.getDomainIcon(domainName);
  }

  formatDateEventList(date: number): string {
    return this.dateService.formatDateEventList(date.toString()); 
  }

  stripHTML(text: string): string {
    return this.uiService.stripHTML(text);
  }

  back(): void {
    if(this.dataService.getSelectedCity() && this.dataService.getStartDate() && this.dataService.getEndDate()) {
      this.router.navigate(['/events', this.dataService.getSelectedCity(), this.dataService.getStartDate(), this.dataService.getEndDate() ]);
    } else {
      this.router.navigate(['/search']);
    }
  }

}
