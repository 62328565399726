import { Injectable } from '@angular/core';
import { Event } from './event';
import { EventInProximity } from './eventInProximity';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private eventsUrl = environment.EVENTS_API_URL;
  private eventUrl = environment.EVENT_API_URL;
  private eventsInProximityUrl = environment.EVENTS_IN_PROXIMITY_API_URL;

  constructor(
    private http: HttpClient,
  ) { }

  getEvents (city: String, startDate: String, endDate: String): Observable<Event[]> {
    const url = `${this.eventsUrl}/${city}/${startDate}/${endDate}`
    return this.http.get<Event[]>(url);
  }

  getEvent (partitionKey: String, rowKey: String): Observable<Event> {
    const url = `${this.eventUrl}/${partitionKey}/${rowKey}`
    return this.http.get<Event>(url);
  }

  getEventsInProximity(venue: String): Observable<EventInProximity[]> {
    const url =`${this.eventsInProximityUrl}/${venue}`
    return this.http.get<EventInProximity[]>(url);
  }
}
