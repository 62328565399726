import { Injectable } from '@angular/core';
import { City } from './city';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CityService {

  private citiesUrl = environment.CITIES_API_URL;

  constructor(
    private http: HttpClient,
  ) { 
    
  }

  getCities (): Observable<City[]> {
    return this.http.get<City[]>(this.citiesUrl)
  }
}
