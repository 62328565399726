import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Event } from '../event';
import { EventService } from '../event.service';
import { DateService } from '../date.service';
import { UiService } from '../ui.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css'],
  
})
export class EventListComponent implements OnInit {

  events: Event[];
  selectedCity: string;
  startDate: string;
  endDate: string;
  startDateFormatted: string;
  endDateFormatted: string;
  eventsLoaded: boolean;

  constructor(
    private eventService: EventService,
    private activatedRoute: ActivatedRoute,
    private dateService: DateService,
    private uiService: UiService,
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    if(this.dataService.getEvents() == null) {
      this.selectedCity = this.activatedRoute.snapshot.paramMap['params']['city'];
      this.startDate = this.activatedRoute.snapshot.paramMap['params']['start'];
      this.endDate = this.activatedRoute.snapshot.paramMap['params']['end'];
      this.dataService.setSelectedCity(this.selectedCity);
      this.dataService.setStartDate(this.startDate);      
      this.dataService.setEndDate(this.endDate);
      this.startDateFormatted = this.dateService.formatDateEventListHeader(this.startDate);
      this.endDateFormatted = this.dateService.formatDateEventListHeader(this.endDate);
      this.getEvents(this.selectedCity, this.startDate, this.endDate);
    } else {
      this.events = this.dataService.getEvents();
      this.selectedCity = this.dataService.getSelectedCity();
      this.startDate = this.dataService.getStartDate();
      this.endDate = this.dataService.getEndDate();
      this.startDateFormatted = this.dateService.formatDateEventListHeader(this.startDate);
      this.endDateFormatted = this.dateService.formatDateEventListHeader(this.endDate);
      this.eventsLoaded = true;
    }
  }

  getEvents(city: String, startDate: String, endDate: String): void {
    this.eventService.getEvents(city, startDate, endDate)
      .subscribe(  
        events =>  {
          this.dataService.setEvents(this.sortEvents(events));
          this.events = this.sortEvents(events);
          this.eventsLoaded = true;
        }
      );
  }

  getDomainIcon(domainName: string): string {
    return this.uiService.getDomainIcon(domainName);
  }

  formatDateEventList(date: string): string {
    return this.dateService.formatDateEventList(date); 
  }

  sortEvents(events: Event[]): Event[] {
    return events.sort((event1: Event, event2: Event) => {
      if(event1.startTime > event2.startTime) {
        return 1;
      }
      if(event1.startTime < event2.startTime) {
        return -1;
      }
      return 0;
    })
  }
}
