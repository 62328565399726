import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  formatDateEventListHeader(date: string): string {
    return moment(new Date(date)).format("DD.MM.YYYY");
  }

  formatDateEventList(date: string): string {
    return moment.unix(+date).format("DD.MM.YYYY, HH:mm");
  }

  getStartDate(when: String): Date {
    const today = new Date();
    let startDate = today;
    if (when == 'Wochenende') {
      if(moment(today).day() == 5 || moment(today).day() == 6 || moment(today).day() == 0) {
        // today is already weekend
      } else {
        startDate = moment().day(5).toDate();
      }
    } else if (when == 'KommendeWoche') {
      if(moment(today).day() == 0) {
        startDate = moment().day(1).toDate();
      } else {
        startDate = moment().day(8).toDate();
      }
    }
    return startDate;
  }

  getEndDate(when: String): Date {
    const today = new Date();
    let endDate = today;
    if(when == 'Woche') {
      if(moment(today).day() == 0) {
        endDate = moment().day(0).toDate();
      } else {
        endDate = moment().day(7).toDate();
      }
    } else if (when == 'Wochenende') {
      if(moment(today).day() == 0) {
        endDate = moment().day(0).toDate();
      } else {
        endDate = moment().day(7).toDate();
      } 
    } else if (when == 'KommendeWoche') {
      if(moment(today).day() == 0) {
        endDate = moment().day(7).toDate();
      } else {
        endDate = moment().day(14).toDate();
      }
    }
    return endDate;
  }

  formatDateForSearch(date: Date): string {
    return moment(date).format("YYYY-MM-DD").toString();
  }

}
