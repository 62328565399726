<div class="container" fxLayout="column" fxLayoutAlign="center center">

  <div fxFlex>
    <mat-toolbar>
      <span>
        <img src="/assets/Logo-Harvee.png"/>
      </span>
    </mat-toolbar>
  </div>

  <div fxFlex>
  <h3>WO ?</h3>
  </div>
  
  <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayout.xs="row" fxLayoutAlign.xs="center center">
    <div class="h-alignCenter" >
     <mat-form-field class="example-full-width" style="width: 300px; text-align: center;">
      <input type="text"
             matInput
             placeholder=""
             aria-label="City"
             [matAutocomplete]="auto"
             [formControl]="cityControl"
             >
        <button mat-button *ngIf="selectedCity" matSuffix mat-icon-button aria-label="Clear" (click)="unsetCityCtrl()">
            <mat-icon>close</mat-icon>
        </button>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let city of cities" [value]="city">
          {{city.key | titlecase}} ({{city.doc_count}} Events)
        </mat-option>
      </mat-autocomplete>
     </mat-form-field>
    </div>
  </div>

  <div>
    <h3>WANN ?</h3>
  </div>

  <div fxLayout="row wrap" fxLayout.sm="row wrap" fxLayout.xs="row wrap">
    <div class="h-alignCenter" fxLayoutGap="15px"> 
    <button mat-raised-button color="primary" (click)="quickSelect('Heute')">Heute</button>
    <button mat-raised-button color="primary" (click)="quickSelect('Woche')">Diese Woche</button>
    <button mat-raised-button color="primary" (click)="quickSelect('Wochenende')">Wochenende</button>
    <button mat-raised-button color="primary" (click)="quickSelect('KommendeWoche')">Nächste Woche</button>
    </div>
  </div>

</div>

