import { Component } from '@angular/core';
import { Title }     from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  private TITLE = "Events in Düsseldorf - Suche - Harvee";

  public constructor(private titleService: Title ) { 
    titleService.setTitle( this.TITLE );
  }

}
