import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { City } from '../city';

import { CityService } from '../city.service';
import { UiService } from '../ui.service';
import { DataService } from '../data.service';
import { DateService } from '../date.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})

export class SearchComponent implements OnInit {

  cities : City[];
  selectedCity : City;
  cityControl =  new FormControl();

  constructor(
    private cityService: CityService,
    private router: Router,
    private uiService: UiService,
    private dataService: DataService,
    private dateService: DateService,
  ) { }

  ngOnInit(): void {
    if(this.dataService.getCities() == null) {
      this.getCities();
    } else {
      this.cities = this.dataService.getCities();
      this.cityControl.patchValue(this.cities[0]);
      this.selectedCity = this.cities[0];
    }
  }

  getCities(): void {
    this.uiService.attachSpinnerToOverlay();
    this.cityService.getCities()
      .subscribe(  
        cities =>  {
          this.dataService.setCities(cities);
          this.cities = cities;
          this.cityControl.patchValue(this.cities[0]);
          // this.cityControl.disable();
          this.selectedCity = this.cities[0];
          this.uiService.detachSpinnerFromOverlay();
        }
      );
  }

  unsetCityCtrl(): void {
    this.cityControl.reset();
  }

  displayFn(city: City): string {
    if(city) {
      return (city.key).charAt(0).toUpperCase() + city.key.slice(1) + " (" + city.doc_count + " Events)";
    } else {
      return "";
    } 
  }

  quickSelect(when: string): void {
    let startDate = this.dateService.getStartDate(when);
    let endDate = this.dateService.getEndDate(when);
    this.dataService.setStartDate(null);
    this.dataService.setEndDate(null);
    this.dataService.setEvents(null);
    this.router.navigate(['/events', this.selectedCity.key, this.dateService.formatDateForSearch(startDate), this.dateService.formatDateForSearch(endDate) ]);
  }

}
