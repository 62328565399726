<div fxLayout="column" fxFlex>

    <div fxFlex style="margin: 0 auto">
      <mat-toolbar>
        <span>
          <img src="/assets/Logo-Harvee.png"/>
        </span>
      </mat-toolbar>
    </div>

    <div *ngIf="!eventsLoaded" style="margin: 0 auto" fxFlex>
        <h3>Events werden geladen ...</h3>
        <mat-progress-spinner mode="indeterminate" style="margin:0 auto;" [diameter]="50"></mat-progress-spinner>
    </div>

    <div *ngIf="eventsLoaded" style="margin: 0 auto" fxFlex>
      <div fxFlex="100" >
          <mat-icon style="vertical-align: bottom;">location_on</mat-icon>
          <span>{{ selectedCity | titlecase }}</span> /
          <mat-icon style="vertical-align: bottom;">access_time</mat-icon>
          {{ startDateFormatted }} - {{ endDateFormatted }}
      </div>
    </div>

    <div fxHide.gt-sm>
      <p></p>
      <p></p>
    </div>
    
    <div *ngIf="eventsLoaded" fxFlex>
      <div id="eventsTable" fxFlex fxLayout="row" fxLayoutAlign="center" >
        <div fxFlex="70" fxFlex.xs="100">
          <mat-list class="events-list">  
              <mat-list-item *ngFor="let event of events; last as last">
              <div fxFlex>  
                <div fxFlex="20%" style="text-align: center; margin: auto;">
                  <img src="{{ getDomainIcon(event.domain) }}" matListAvatar title="{{ event.domain }}">
                </div>
                <div fxLayout="column" fxFlex="80%">
                  <div fxFlex style="font-size: 18px; text-align: left; font-weight: bold;" class="textarea">
                    <a [routerLink]="['/event', event.PartitionKey, event.RowKey]" >{{ event.name }}</a>
                  </div>
                  <div fxFlex class="textarea">
                    <a [routerLink]="['/event', event.PartitionKey, event.RowKey]">{{ event.venue }}</a>
                  </div>
                  <div fxFlex style="text-align: left;" class="textarea">
                    <label style="font-size: 11px; font-weight: bold;">{{ formatDateEventList(event.startTime) }} Uhr</label>
                  </div>
                </div>
              </div>
              <mat-divider *ngIf="!last"></mat-divider>
              </mat-list-item>
          </mat-list> 
        </div>
      </div>
    </div>

</div>
