<div fxLayout="column" fxLayoutAlign="center center">

    <div fxFlex style="margin: 0 auto">
      <mat-toolbar>
        <span>
          <img src="/assets/Logo-Harvee.png"/>
        </span>
      </mat-toolbar>
    </div>

    <div *ngIf="!eventLoaded" style="margin: 0 auto" fxFlex>
        <mat-progress-spinner mode="indeterminate" style="margin:0 auto;" [diameter]="30"></mat-progress-spinner>
    </div>

    <div *ngIf="eventLoaded" fxFlex>
        <div id="eventCard" fxLayout="row wrap" fxLayoutAlign="center">
            <div fxFlex="60" fxFlex.sm="80" fxFlex.xs="100" style="min-width: 290px;" class="textarea">
                <mat-card>
                    <mat-card-header>
                        <img src="{{ getDomainIcon(event.domain) }}" mat-card-avatar>
                        <mat-card-title style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden; width:100%;">{{ event.name }}</mat-card-title>
                        <mat-card-subtitle style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden; width:100%;">{{ event.venue }}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-title>
                            <span class="mat-headline">{{ formatDateEventList(event.startTime) }} Uhr</span>
                    </mat-card-title>
                    <mat-card-content *ngIf="event.description" >
                        {{ stripHTML(event.description) }}
                    </mat-card-content>
                    <mat-card-actions fxLayout="row" fxLayoutAlign="center center" style="z-index: 0;" fxLayoutGap="5px">
                        <button mat-mini-fab color="primary" (click)="back()">
                            <mat-icon>arrow_back</mat-icon>
                        </button>
                        <a mat-mini-fab href="{{ event.link }}" target="_new" color="primary">
                            <mat-icon>link</mat-icon>
                        </a>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>

    <div style="margin: 0 auto; margin-top: 6px; margin-bottom: 6px;" fxFlex>
        Events in der direkten Umgebung:
    </div>

    <div *ngIf="!eventsInProximityLoaded" style="margin: 0 auto" fxFlex>
        <mat-progress-spinner mode="indeterminate" style="margin:0 auto;" [diameter]="15"></mat-progress-spinner>
    </div>

    <div *ngIf="eventsInProximityLoaded">
        <div fxLayout="row wrap" fxLayout.sm="row wrap" fxLayout.xs="row wrap" fxLayoutGap="15px" fxLayoutAlign="center center">
                <mat-card *ngFor="let event of eventsInProximity" class="mini-card">
                    <mat-card-header>
                        <mat-card-title style="font-size: 14px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden !important; width:180px;">{{ event.name }}</mat-card-title>
                        <mat-card-subtitle style="font-size: 14px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden !important; width:180px;">{{ event.venue }}</mat-card-subtitle>
                        <mat-card-subtitle style="font-size: 14px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden !important; width:180px;">{{ formatDateEventList(event.startTime) }} Uhr</mat-card-subtitle>
                        <mat-card-subtitle style="font-size: 14px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden !important; width:180px;">{{ event.distance }}m entfernt.</mat-card-subtitle>
                    </mat-card-header>
                </mat-card>
        </div>
    </div>


</div>