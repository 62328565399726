import { Injectable } from '@angular/core';

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material/progress-spinner';

import { City } from './city';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private spinnerTopRef: OverlayRef;

  public CITY_SELECTION_LABEL = "Wähle eine Stadt aus";

  constructor(
    private overlay: Overlay,
  ) { 
    this.spinnerTopRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });
  }

  attachSpinnerToOverlay (): void {
    this.spinnerTopRef.attach(new ComponentPortal(MatSpinner)) 
  }

  detachSpinnerFromOverlay (): void {
    this.spinnerTopRef.detach();
  }

  returnAutoCompleteCityLabel (city: City): string {
    return (city.key).charAt(0).toUpperCase() + city.key.slice(1) + " (" + city.doc_count + " Events)";
  }

  getDomainIcon (domainName: string): string {
    if(domainName == "http://www.meetup.com")
        return "assets/meetup_logo_square.jpg"
    if(domainName == "http://www.coolibri.de")
        return "assets/Profilbild_coolibri.jpg"
    if(domainName == "http://www.metzgereischnitzel.de")
        return "assets/brause_logo_square.jpg"
    if(domainName == "http://www.harvee.de")
        return "assets/pille.jpg"
  }

  stripHTML(text: string): string {
    var div = document.createElement("div");
    div.innerHTML = text;
    return div.textContent || div.innerText || "";
  }
  
}
